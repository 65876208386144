<template>
    <v-container grid-list-md>
        <v-row justify="space-around">
            <div class="about">
                <h1>P�gina de datos del sistema</h1>
                <v-btn color="secondary" @click="otro">otro</v-btn>
                <p>Datos {{ datos }}</p>
                <p>Imagenes {{ images }}</p>
                <p>{{ publicPath }}</p>
                <p>Ambiente {{ nodeenv }}</p>
                <v-btn color="warning" @click="getAll">Usuarios</v-btn>
                <ul>
                    <li v-for="(item, index) in items" :key="index">
                        {{ item.userName }}
                    </li>
                </ul>
                <v-btn color="yellow" @click="error('Mensage de error')">Error</v-btn>
                <v-btn color="yellow" @click="success('Mensage de suceso')">Suceso</v-btn>
                <v-btn color="yellow" @click="clear('borrado de alerta')">Borra</v-btn>
                <v-card max-width="400px" class="pl-12">
                    <v-card-text>
                        <autocompletaProductor v-model="titular"
                                               titulo="Productor">
                        </autocompletaProductor>
                    </v-card-text>
                </v-card>
            </div>
        </v-row>
    </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import autocompletaProductor from '@/components/Productor/autocompletaProductor';

export default {
    data() {
        return {
            titular: { nombre: '', id: null, fullName: '' },
            datos: '',
            images: '',
            publicPath: '',
            nodeenv: ''
        };
    },

    computed: {
        ...mapState('account', ['items']),
            ...mapState(['loggedUser']),
    },

    methods: {
        ...mapActions('account', ['getAll']),
        ...mapActions('alert', ['error', 'success', 'clear']),
        otro() {
            this.datos = process.env.VUE_APP_API_ENDPOINT;
            this.images = process.env.VUE_APP_IMAGE_ENDPOINT;
            this.nodeenv = process.env.NODE_ENV;
            this.publicPath = process.env.publicPath;
            this.loggedUser.loggedIn = !this.loggedUser.loggedIn;
        }
    },
    mounted() {},
    components: {
        autocompletaProductor
    }
};
</script>
