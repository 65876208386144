<template>
    <v-layout row wrap class="pa-0 mt-0">
        <v-autocomplete :disabled="disabled"
                        v-model="propValue"
                        :items="items"
                        item-text="nombre"
                        item-value="id"
                        :label="etiqueta"
                        :prepend-inner-icon="icono"
                        :loading="isLoading"
                        :search-input.sync="search"
                        placeholder="Busca o agrega"
                        @click:prepend-inner="clickIcon"
                        @input="update">
        </v-autocomplete>

        <v-dialog v-model="propDialog"
                  max-width="500px"
                  max-heigh="300px"
                  persistent>
            <productorDialogo @closeDlg="propDialog = false"
                              @modelo="change($event)" />
        </v-dialog>
    </v-layout>
</template>
<script>
    import { mapState, mapActions } from 'vuex';

    import productorDialogo from '@/components/Productor/ProductorDialogo';

    export default {
        name: 'autocompletaProductor',
        props: ['value', 'titulo', 'disabled', 'clienteId'],
        data: () => ({
            search: ""
        }),

        methods: {
            ...mapActions('productor', [
                'initialize',
                'editItem',
                'setDialog',
                'setItems',
                'getAutocomplete'
            ]),

            update(newValue) {
                let val = this.items.find(x => x.id === newValue)
                if (val != null && val !== undefined) {
                    this.propValue = val;
                    this.$emit('change');
                }
            },

            clickIcon() {
                this.editItem(this.value);
            },

            change(item) {
                this.propValue = item;
                this.$emit('change');
            },

        },

        computed: {
            ...mapState('productor', ['dialog', 'isLoading', 'items']),

            icono() {
                return this.value.id == 0 || this.value.id == null
                    ? 'mdi-plus'
                    : 'mdi-pencil';
            },

            propValue: {
                get() {
                    if (
                        this.value == null || this.value == undefined ||
                        this.value.id == null || this.value.id == 0) {
                        this.setItems([]);
                        return this.value;
                    }
                    else {
                        let pp = { id: this.value.id, nombre: this.value.nombre };
                        this.setItems([pp]);
                        return pp;
                    }
                },

                set(value) {
                    this.$emit('input', { id: value.id, nombre: value.nombre});
                }
            },


            propDialog: {
                get() {
                    return this.dialog;
                },
                set(value) {
                    this.setDialog(value);
                    this.isLoading = false;
                }
            },

            etiqueta() {
                return this.titulo + (this.propValue.id == null || this.propValue.id == 0 ? '' : (this.propValue.id + ' ' + this.propValue.nombre));
            }
        },

        watch: {
            search(val) {
                if (val === null || val === '' ||
                    (val.nombre === undefined && val.id !== undefined) ||
                    val === this.propValue.nombre) {
                    if (val === null || val === '') {
                        this.propValue = { id: null, nombre: val };
                        this.setItems([]);
                    }
                    return;
                }

                if (this.propValue.id !== null) {
                    this.setItems([]);
                }

                // Items have already been loaded
                if (this.items.length > 0 ||
                    this.items == undefined ||
                    this.isLoading ||
                    val === null ||
                    val === '')
                    return;

                // Items have already been requested
                if (this.isLoading) return

                this.getAutocomplete({ clienteId: this.clienteId, val: val });

                this.propValue = { id: null, nombre: val };
            },

        },
        components: {
            productorDialogo
        }
    };
</script>
